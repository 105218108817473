import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ('../views/index.vue'),
        children: [{
                path: '/',
                name: 'home',
                component: () =>
                    import ('../views/Home.vue'),
                meta: {
                    index: 0
                }
            },
            // 引文首页
            {
                path: '/en',
                name: 'homeEn',
                component: () =>
                    import ('../views/homeEn.vue'),
                meta: {
                    index: 0
                }
            },
            // 列表页
            {
                path: '/list/:id',
                name: 'list',
                component: () =>
                    import ('../views/list.vue'),
                meta: {
                    index: 2
                }
            },
            // 列表页2
            {
                path: '/listTwo/:id',
                name: 'listTwo',
                component: () =>
                    import ('../views/listTwo.vue'),
                meta: {
                    index: 2
                }
            },
            // 列表页3
            {
                path: '/listThird/:id',
                name: 'listThird',
                component: () =>
                    import ('../views/listThird.vue'),
                meta: {
                    index: 2
                }
            },
            // 详情页
            {
                path: '/detail/:parentId/:id',
                name: 'detail',
                component: () =>
                    import ('../views/detail.vue'),
                meta: {
                    index: 2
                }
            },
            // 栏目详情
            {
                path: '/listPage/:id',
                name: 'listPage',
                component: () =>
                    import ('../views/listPage.vue'),
                meta: {
                    index: 1
                }
            },
            // 图片列表
            {
                path: '/listPic/:id',
                name: 'listPic',
                component: () =>
                    import ('../views/listPic.vue'),
                meta: {
                    index: 1
                }
            },
            // 图片列表2
            {
                path: '/listPicTwo/:id',
                name: 'listPicTwo',
                component: () =>
                    import ('../views/listPicTwo.vue'),
                meta: {
                    index: 1
                }
            },
            // 视频列表
            {
                path: '/listVideo/:id',
                name: 'listVideo',
                component: () =>
                    import ('../views/listVideo.vue'),
                meta: {
                    index: 1
                }
            },
            // 举报
            {
                path: '/report',
                name: 'report',
                component: () =>
                    import ('../views/report.vue'),
                meta: {
                    index: 1
                }
            },
            // 专题专栏
            {
                path: '/special',
                name: 'special',
                component: () =>
                    import ('../views/special.vue'),
                meta: {
                    index: 1
                }
            },
            // 搜索
            {
                path: '/search/:title',
                name: 'search',
                component: () =>
                    import ('../views/search.vue'),
                meta: {
                    index: 1
                }
            },
            // 党建页
            {
                path: '/party',
                name: 'party',
                component: () =>
                    import ('../views/party.vue'),
                meta: {
                    index: 1
                }
            },
            // 纪检举报
            {
                path: '/inform',
                name: 'inform',
                component: () =>
                    import ('../views/inform.vue'),
                meta: {
                    index: 1
                }
            },
            // 纪检举报--提价
            {
                path: '/reportCgicop',
                name: 'reportCgicop',
                component: () =>
                    import ('../views/reportCgicop.vue'),
                meta: {
                    index: 1
                }
            },

        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router