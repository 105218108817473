export const m = {
    // 顶部参数
    searchTxt: '请输入搜索关键字',
    // 底部参数
    footerLinkName: '友情链接',
    footerLink: [{
            name: '中华人民共和国外交部',
            url: 'https://www.fmprc.gov.cn/'
        },
        {
            name: '中华人民共和国商务部',
            url: 'http://www.mofcom.gov.cn/'
        },
        {
            name: '中国一带一路网',
            url: 'https://www.yidaiyilu.gov.cn/'
        },
        {
            name: '甘肃省商务厅',
            url: 'http://swt.gansu.gov.cn/'
        },
        {
            name: '甘肃省住房和城乡建设厅',
            url: 'http://zjt.gansu.gov.cn/?tabid=1'
        },
        {
            name: '甘肃省国资委',
            url: 'http://gzw.gansu.gov.cn/'
        },
        {
            name: '甘肃建投',
            url: 'http://www.gsjtw.cc/'
        },
        {
            name: '品味网',
            url: 'http://www.cgicop-pinwei.com/'
        },
    ],
    footerCompanyName: '中国甘肃国际经济技术合作有限公司',
    footerLeft: `<li>中国甘肃国际经济技术合作有限公司</li>
    <li>网址：http://www.cgico.cn &nbsp;&nbsp;&nbsp;&nbsp; 企业邮箱：cgicop@cgicop.com</li>
    <li>传真：931-2658644</li>
    <li>单位地址：中国甘肃兰州市七里河区西津东路575号建工大厦</li> 
    <li>单位电话：0931-2216431</li> 
    <li>
      <span>备案编号：
        <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备11000312号-1</a>&nbsp;&nbsp;甘公网安备：
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010302000333" target="_blank">62010302000333</a>
      </span>
    </li>
    <li>本网所有文字、图片和音视频资料，版权均属本公司所有，本站样式已版权保护，不得随意转载、镜像</li> 
    <li>与仿制，违者必究。</li> `,
    footerRight: `扫一扫 关注中甘国际公众号`,
    // 标题参数
    titleNewsSec01: '新闻中心',
    titleNewsSec01Sub: [{
            id: 1,
            title: '公司新闻'
        },
        {
            id: 2,
            title: '行业新闻'
        },
        {
            id: 3,
            title: '集团要闻'
        },
    ],
    titleNewsSec01SubMore: {
        txt: '更多',
        url: '/list/01800001'
    },
    titleNewsSec02: '项目展示',
    titleNewsSec02SubMore: {
        txt: '更多',
        url: '/list/01920001'
    },
    titleNewsSec03: '中甘和我',
    titleNewsSec03SubMore: {
        txt: '更多',
        url: '/listPicTwo/01830005'
    },
    listPageOption: '当前位置',
    listPageHome: '首页',
    listPageDetail: '详情',
    detailTime: '发布时间',
    detailView: '浏览',
    detailCount: '次',

    webId: '688336EC-033F-4C10-8573-726BFE0C0AE5',
}